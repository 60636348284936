<template>
  <div>
    <div id="statementContentHeaderContainer">
      <div class="d-flex justify-space-between">
        <h3 id="statementContentContainerTitle">
          <span class="stepNumber">{{ '0' + activeStep }}</span>
          Confirm Aggregator Commission
        </h3>
      </div>

      <div id="aggregatorBody">
        <div class='text-center'>Is this your aggregator’s monthly commission?</div>

        <div>
          <div>
            <table style="border:none;">
              <tbody>
              <tr>
                <td>
                  <v-text-field prefix="%" v-model="aggregator_options.aggregator_percentage"
                                :placeholder="!allowEditPercent ? '------------' : 'Ex: 5'"
                                :disabled="!allowEditPercent"
                                :error-messages="getFieldErrors('aggregator_percentage')"
                                :error="fieldHasErrors('aggregator_percentage')"
                                reverse
                                @input="updateAggregatorPercentage"
                  />
                </td>
                <td
                  style="padding-left:40px;padding-right:40px; font-family: 'Roboto';font-style: normal;font-weight: 400;font-size: 18px;line-height: 28px;padding-top:16px;">
                  OR
                </td>
                <td>
                  <v-text-field prefix="$" v-model="aggregator_options.aggregator_flat_rate"
                                :placeholder="!allowEditFlat ? '------------' : 'Ex: 200'"
                                :disabled="!allowEditFlat"
                                :error-messages="getFieldErrors('aggregator_flat_rate')"
                                :error="fieldHasErrors('aggregator_flat_rate')"
                                @input="updateAggregatorFlatRate"
                  />
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="text-center mt-8">
            <v-btn color="primary" @click.prevent="saveAndContinue">
              CONFIRM
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import HasFormErrors from "@/config/mixins/HasFormErrors";
import _ from "lodash";


export default {
  props: ['activeStep'],
  mixins: [HasFormErrors],
  components: {},
  data() {
    return {
      aggregator_options: {
        aggregator_rate_type: '',
        aggregator_percentage: '',
        aggregator_flat_rate: '',
      },
    }
  },
  methods: {
    updateAggregatorPercentage(val){
      if (val) {
        this.aggregator_options.aggregator_rate_type = 'percentage';
      } else {
        this.aggregator_options.aggregator_rate_type = '';
      }

      this.clearFormException();
    },
    updateAggregatorFlatRate(val){
      if (val) {
        this.aggregator_options.aggregator_rate_type = 'flat';
      } else {
        this.aggregator_options.aggregator_rate_type = '';
      }

      this.clearFormException();
    },
    toMonthName(monthNumber) {
      const date = new Date()

      date.setFullYear(2023,parseInt(monthNumber) - 1,1);

      return date.toLocaleString('en-US', {
        month: 'long',
      })
    },
    loadAggregatorPercentage() {
      this.$api.commissionStatements.loadAggregatorPercentage({
        date: this.date
      }).then(({data:{attributes}}) => {
        this.aggregator_options = attributes;
      })
    },
    saveAndContinue() {
      this.$api.commissionStatements.updateAggregatorPercentage({
        date: this.date,
        aggregator_rate_type: this.aggregator_options.aggregator_rate_type,
        aggregator_percentage: this.aggregator_options.aggregator_percentage,
        aggregator_flat_rate: this.aggregator_options.aggregator_flat_rate,
      }).then((response) => {
        this.$emit('nextStep')
      }).catch((error) => {
        this.setFormException(error);
      });
    },
  },
  watch: {

  },
  mounted() {
    this.loadAggregatorPercentage();
  },
  computed: {
    ...mapGetters({
      currentStep: 'commissions/monthlyReview/getCurrentStep',
    }),
    date() {
      return this.$route.params.year + '-' + this.$route.params.month
    },
    year() {
      return this.$route.params.year
    },
    month() {
      return this.toMonthName(this.$route.params.month)
    },
    allowEditPercent() {
      return this.aggregator_options.aggregator_rate_type !== 'flat';
    },
    allowEditFlat() {
      return this.aggregator_options.aggregator_rate_type !== 'percentage';
    }
  },
}
</script>

<style lang="scss" scoped>
#statementContentHeaderContainer {
  background: #fff;
  padding: 24px 32px 16px 32px;
  margin-bottom: 2px;
}

#filterPanel {
  margin-top: 32px;
}

#aggregatorBody {
  max-width:400px;
  margin-left: auto;
  margin-right: auto;
  margin-top:16px;
  color: #3A3541;
}
</style>
